@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300&display=swap');

html, body, #app, #app>div, #root {
  font-family: 'Signika', sans-serif;
  height: 100%;
  font-size: 16px;

  h1, h2, h3, h4, h5 {
    font-weight: 600;
  }
}


.initial-container {
  background: $color-secondary-1 !important;
}

.btn {
  font-size: 14px;
}

.btn-primary, .btn-primary:hover {
  background-color: $color-primary-1 !important;
  border-color: $color-primary-1 !important;
}

.btn-secondary {
  background-color: $color-secondary-1 !important;
  border-color: $color-secondary-1 !important;
}
