@import "src/assets/css/variables";

.main-container {
  padding: 30px;

  .content {
    background: $color-primary-1;
    padding: 15px;
    border-radius: 10px;
    min-height: 100vh !important;

    .principal-content {
      padding: 20px 0;
      color: white;
    }
  }
}