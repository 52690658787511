@import "src/assets/css/variables";

#top-bar {
  width: 100%;

  .nav-tabs {
    background: $color-secondary-1 !important;
    border-radius: 15px;
    font-size: 14px;
    color: white;
  }
  .nav-item {
    padding: 0 15px;

    a {
      color: white;
      text-decoration: none !important;
      &.active {
        background: $color-primary-1;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 0 0 .25rem rgba(49,132,253,.5);
      }
    }
  }

  .btn-* {
    border-radius: 10px;
    &:hover {
      font-size: 12px !important;
    }
  }
}