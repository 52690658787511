@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

#about-container {
  img {
    width: 100%;
    object-fit: contain;

    @include media-breakpoint-up(md) {
      max-height: 120px;
    }
  }
}